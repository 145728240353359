import { Link } from 'gatsby';
import React from 'react';

import { ImageSharpData } from 'types';
import { Container, Image, NetlifyImage, Source } from './BlogPostHero.style';

interface Props {
	featuredImage?: ImageSharpData;
	path?: string;
	imageSource?: string;
	netlifyImage?: string;
	isNetlifyWidget?: boolean;
}

export function BlogPostHero({
	featuredImage,
	path,
	isNetlifyWidget = false,
	imageSource,
	netlifyImage
}: Props) {
	if (path) {
		return (
			<Container>
				<Link to={path}>
					{!isNetlifyWidget && featuredImage && (
						<Image fluid={featuredImage.childImageSharp.fluid} />
					)}
				</Link>
			</Container>
		);
	}

	return (
		<Container>
			{!isNetlifyWidget && featuredImage && (
				<Image fluid={featuredImage.childImageSharp.fluid} />
			)}
			{isNetlifyWidget && <NetlifyImage src={netlifyImage} />}
			{imageSource && <Source>{`Image Source: ${imageSource}`}</Source>}
		</Container>
	);
}
