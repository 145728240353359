import styled from 'styled-components';

import { Banner as BaseBanner } from 'components/UI/Banner';
import { Button as BaseButton } from 'components/UI/Button';
import { TextLink as BaseTextLink } from 'components/UI/TextLink';
import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';
import { MediaQueries, Sizes } from 'environment';

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	position: relative;
`;

export const Banner = styled(BaseBanner)`
	@media ${MediaQueries.tablet} {
		height: 70rem;

		& > div {
			top: 50%;
		}
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	@media ${MediaQueries.phone} {
		padding: 0 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding: 0 2rem;
	}
`;

export const Layout = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;

	@media ${MediaQueries.desktop} {
		flex-direction: row;
	}
`;

export const TextLink = styled(BaseTextLink)`
	display: inline-block;

	@media ${MediaQueries.tablet} {
		margin-bottom: 4rem;
	}

	@media ${MediaQueries.desktop} {
		margin-bottom: 12rem;
	}
`;

export const Button = styled(BaseButton)`
	margin-top: 8rem;
	margin-bottom: 12rem;

	@media ${MediaQueries.phone} {
		margin-bottom: 6rem;
	}
`;
