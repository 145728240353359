import styled from 'styled-components';

import { MediaQueries } from 'environment';

export const Title = styled.h2`
	text-align: center;

	@media ${MediaQueries.phone} {
		font-size: 2.4rem;
		line-height: 2.9rem;
	}

	@media ${MediaQueries.desktop} {
		margin-bottom: 8rem;
	}
`;

export const List = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	@media ${MediaQueries.desktop} {
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
	}
`;
