import React from 'react';

import { PostListItem } from 'types';

import { List, Title } from './BlogPostPreviewList.style';
import { BlogPostPreview } from '../BlogPostPreview';

interface Props {
	posts: PostListItem[];
	title?: string;
	wrap?: number;
}

export function BlogPostPreviewList({ posts, title, wrap }: Props) {
	return (
		<>
			{title && <Title>{title}</Title>}
			<List style={{ flexWrap: wrap ? 'wrap' : 'unset' }}>
				{posts.map(post => (
					<BlogPostPreview key={`blog-post-preview-${post.id}`} post={post} wrap={wrap} />
				))}
			</List>
		</>
	);
}
