import Img from 'gatsby-image';
import styled, { css } from 'styled-components';

import { TextLink as BaseTextLink } from 'components/UI/TextLink';
import { MediaQueries } from 'environment';

interface Props {
	wrap?: number;
}

export const Container = styled.div<Props>`
	display: flex;
	flex-direction: column;
	width: 100%;

	@media ${MediaQueries.phone} {
		margin-top: 4rem;
	}

	@media ${MediaQueries.tablet} {
		margin-top: 8rem;
	}

	@media ${MediaQueries.desktop} {
		margin: 0 1rem;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}

		${({ wrap }) =>
			wrap &&
			css`
				width: calc((100% / ${wrap}) - 1rem);
				margin: 0;
				margin-bottom: 8rem;
			`}
	}
`;

export const Image = styled(Img)`
	cursor: pointer;
	margin-bottom: 3rem;

	@media ${MediaQueries.phone} {
		height: 21.2rem;
		margin-bottom: 2rem;
	}

	@media ${MediaQueries.tablet} {
		height: 39.2rem;
		margin-bottom: 4rem;
	}

	@media ${MediaQueries.desktop} {
		height: 24rem;
		margin-bottom: 3rem;
	}
`;

export const Title = styled.h4`
	cursor: pointer;
	margin-bottom: 1rem;

	:hover {
		text-decoration: underline;
	}
`;

export const ReleaseDate = styled.span`
	margin-bottom: 2rem;

	@media ${MediaQueries.desktop} {
		margin-bottom: 1rem;
	}
`;

export const Excerpt = styled.p`
	margin-bottom: 2rem;

	@media ${MediaQueries.desktop} {
		margin-bottom: 1rem;
	}
`;

export const TextLink = styled(BaseTextLink)`
	margin-bottom: 1rem;
	width: fit-content;
`;
