import Img from 'gatsby-image';
import styled from 'styled-components';
import { MediaQueries, Colors } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 4rem 0;

	@media ${MediaQueries.phone} {
		margin-bottom: 2rem;
	}
`;

export const Image = styled(Img)`
	width: 100%;

	img {
		object-fit: cover;
	}
`;

export const NetlifyImage = styled.img`
	width: 100%;
	object-fit: cover;
`;

export const Source = styled.label`
	display: flex;
	justify-content: flex-end;
	font-size: 1.4rem;
	line-height: 2.2rem;
	font-weight: 500;
	color: ${Colors.gray[7]};
	margin-top: 1rem;
	border-bottom: 0.2rem transparent solid;
`;
