import { formatDistanceToNow, parse } from 'date-fns';
import { Link } from 'gatsby';
import React from 'react';

import { BLOG_POST_DATE_FORMAT } from 'consts';
import data from 'data/landing.json';
import { PostListItem } from 'types';

import { Container, Excerpt, Image, ReleaseDate, TextLink, Title } from './BlogPostPreview.style';

interface Props {
	post: PostListItem;
	wrap?: number;
}

export function BlogPostPreview({ post, wrap }: Props) {
	const { featuredImage, featuredImage_alt, title, date, excerpt, path } = post;

	return (
		<Container wrap={wrap}>
			{featuredImage && (
				<Link to={path + '/'}>
					<Image fluid={featuredImage.childImageSharp.fluid} alt={featuredImage_alt} />
				</Link>
			)}
			<Link to={path + '/'}>
				<Title>{title}</Title>
			</Link>
			<ReleaseDate>
				{formatDistanceToNow(parse(date, BLOG_POST_DATE_FORMAT, new Date()), {
					addSuffix: true
				})}
			</ReleaseDate>
			<Excerpt>{excerpt}</Excerpt>
			<TextLink to={path + '/'}>{data.posts.cta}</TextLink>
		</Container>
	);
}
