import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import { MediaQueries } from 'environment';

interface Props {
	isLink?: boolean;
}

export const Title = styled.h2<Props>`
	margin-bottom: 4rem;

	@media ${MediaQueries.phone} {
		font-family: 'Open Sans';
		font-size: 3.6rem;
		font-weight: 600;
		line-height: 5rem;
		margin-bottom: 2rem;
	}

	${({ isLink = false }) =>
		isLink &&
		css`
			:hover {
				cursor: pointer;
				text-decoration: underline;
			}
		`}
`;

export const AuthorContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 4rem;

	@media ${MediaQueries.phone} {
		margin-bottom: 2rem;
	}
`;

export const AuthorImage = styled(Img)`
	width: 8rem;
`;

export const NetlifyAuthorImage = styled.img`
	border-radius: 10rem;
	min-width: 6rem;
	max-width: 7.5rem;
`;

export const AuthorInfo = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 2rem;
`;

export const AuthorName = styled.p`
	font-weight: 600;
`;

export const PostDate = styled.span``;

export const Excerpt = styled.p`
	margin-bottom: 4rem;
`;
