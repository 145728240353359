import { formatDistanceToNow, parse } from 'date-fns';
import React, { useState } from 'react';

import { BLOG_POST_DATE_FORMAT } from 'consts';
import blog from 'data/blog.json';
import team from 'data/team.json';
import analytics from 'data/analytics.json';
import { MediaQueries } from 'environment';
import { useMediaQuery, useQueryContext } from 'hooks';

import { BlogPostHeader } from '../BlogPostHeader';
import { BlogPostHero } from '../BlogPostHero';
import { BlogPostPreviewList } from '../BlogPostPreviewList';
import { BlogUpdates } from '../BlogUpdates';
import { Banner, Button, Container, Content, Layout, TextLink } from './BlogPostList.style';

export function BlogPostList() {
	const isPhone = useMediaQuery(MediaQueries.phone);
	const isDesktop = useMediaQuery(MediaQueries.desktop);
	const { images, posts } = useQueryContext();

	const [loadedPosts, setLoadedPosts] = useState(posts.slice(1, 5));

	const { path, title, date, featuredImage, excerpt, authorImage, author } = posts[0];
	const member = team.members.find(member => member.name === author);

	const formattedDate = formatDistanceToNow(parse(date, BLOG_POST_DATE_FORMAT, new Date()), {
		addSuffix: true
	});

	function handleLoadMore() {
		setLoadedPosts(posts.slice(1, loadedPosts.length + 5));
	}

	return (
		<Container>
			{!isPhone && images.banners.pricing && (
				<Banner image={images.banners.pricing} rotate={180} />
			)}
			<Content>
				<BlogPostHero featuredImage={featuredImage} path={path + '/'} />
				<Layout>
					<div>
						<BlogPostHeader
							title={title}
							date={formattedDate}
							path={path + '/'}
							excerpt={excerpt}
							member={member}
							authorImage={authorImage ? authorImage.childImageSharp : undefined}
						/>
						<TextLink to={path + '/'}>{blog.read}</TextLink>
						<BlogPostPreviewList posts={loadedPosts} wrap={2} />
						<Button
							name={analytics.blogListLoadMore}
							light
							disabled={loadedPosts.length === posts.length - 1}
							width={isDesktop ? 38 : undefined}
							onClick={handleLoadMore}
						>
							{blog.load}
						</Button>
					</div>
					<BlogUpdates />
				</Layout>
			</Content>
		</Container>
	);
}
