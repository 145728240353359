import { Link } from 'gatsby';
import React from 'react';

import { ImageSharpNode, Member } from 'types';
import {
	AuthorContainer,
	AuthorImage,
	AuthorInfo,
	AuthorName,
	Excerpt,
	NetlifyAuthorImage,
	PostDate,
	Title
} from './BlogPostHeader.style';

interface Props {
	title: string;
	date: string;
	path?: string;
	excerpt?: string;
	member?: Member;
	authorImage?: ImageSharpNode;
	netlifyAuthorImage?: string;
	isNetlifyWidget?: boolean;
}

export function BlogPostHeader({
	title,
	date,
	path,
	excerpt,
	member,
	authorImage,
	netlifyAuthorImage,
	isNetlifyWidget = false
}: Props) {
	return (
		<div>
			{path ? (
				<Link to={path}>
					<Title isLink>{title}</Title>
				</Link>
			) : (
				<Title>{title}</Title>
			)}
			{member && (
				<AuthorContainer>
					{!isNetlifyWidget && authorImage && <AuthorImage fluid={authorImage.fluid} />}
					{isNetlifyWidget && <NetlifyAuthorImage src={netlifyAuthorImage} />}
					<AuthorInfo>
						<AuthorName>by {member.name}</AuthorName>
						<PostDate>{date}</PostDate>
					</AuthorInfo>
				</AuthorContainer>
			)}
			{excerpt && <Excerpt>{excerpt}</Excerpt>}
		</div>
	);
}
