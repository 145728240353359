import React, { useState } from 'react';

import { Button, TextInput } from 'components/UI';
import { updates } from 'data/post.json';
import analytics from 'data/analytics.json';
import { MediaQueries } from 'environment';
import { useMediaQuery } from 'hooks';

import { Container, Description, Form, Row, Title } from './BlogUpdates.style';

export function BlogUpdates() {
	const isTablet = useMediaQuery(MediaQueries.tablet);
	const isDesktop = useMediaQuery(MediaQueries.desktop);
	// const posts = useInstagramPosts();

	const [email, setEmail] = useState('');

	return (
		<Container>
			<Form>
				<Title>{updates.title}</Title>
				<Description>{updates.description}</Description>
				<Row>
					<TextInput
						placeholder={updates.placeholder}
						value={email}
						onChange={e => setEmail(e.target.value)}
					/>
				</Row>
				<Button
					name={analytics.blogListSignUpNewsletter}
					width={isDesktop ? 28 : isTablet ? 62 : 33.5}
				>
					{updates.cta}
				</Button>
			</Form>
			{/* {posts.length > 0 && (
				<SocialContainer>
					<Title>{social.title}</Title>
					<SocialImages>
						{posts.map(({ id, localFile }) => (
							<SocialLink
								key={id}
								href={`${socialConfiguration.instagramPostLink}${id}`}
								rel="nofollow noreferrer"
								target="_blank"
							>
								<Image
									fluid={localFile.childImageSharp.fluid}
									alt={`Instagram-Image-${id}`}
								/>
							</SocialLink>
						))}
					</SocialImages>
				</SocialContainer>
			)} */}
		</Container>
	);
}
