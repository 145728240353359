import Img from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import styled from 'styled-components';

import { MediaQueries } from 'environment';

export const Container = styled.div`
	@media ${MediaQueries.desktop} {
		margin-left: 12rem;
	}
`;

export const Form = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const Title = styled.h4`
	font-weight: bold;

	@media ${MediaQueries.phone}, ${MediaQueries.tablet} {
		text-align: center;
	}
`;

export const Description = styled.span`
	margin-top: 1rem;
	margin-bottom: 2rem;

	@media ${MediaQueries.phone}, ${MediaQueries.tablet} {
		text-align: center;
	}
`;

export const SocialContainer = styled.div`
	margin-top: 12rem;

	@media ${MediaQueries.phone} {
		margin: 6rem 0;
	}

	@media ${MediaQueries.tablet} {
		margin-bottom: 12rem;
	}
`;

export const SocialImages = styled.div`
	display: flex;
	flex-wrap: wrap;
	position: relative;
	margin-top: 2rem;

	a {
		margin-bottom: 0.5rem;

		&:nth-of-type(3n + 2) {
			margin-left: 0.5rem;
			margin-right: 0.5rem;
		}
	}

	div[class*='Image'] {
		width: 9rem;
		height: 9rem;

		@media ${MediaQueries.tablet} {
			width: 20rem;
			height: 20rem;
		}

		@media ${MediaQueries.phone} {
			width: 10.5rem;
			height: 10.5rem;
		}
	}

	@media ${MediaQueries.desktop} {
		max-width: 28rem;
	}
`;

export const Image = styled(Img)`
	width: 100%;

	img {
		object-fit: cover;
	}
`;

export const SocialLink = styled(OutboundLink)`
	cursor: pointer;
`;

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 2rem;
`;
